export default [
  {
    name: 'Gabriel Tabbal',
    description:
      'Game-changing tool. Anki2Notion will take your studying to the next level. It will undeniably save you an enormous amount of time. Gone are the days of manually creating flashcards.',
    title: 'undergraduate student',
    profile: null,
  },
  {
    name: 'King Waffle',
    title: 'YouTube comment',
    description: 'god tier addon',
    profile:
      'https://yt3.ggpht.com/ytc/AKedOLQuCkq63lQqeLomKdKJe8ku2WOfb5ON1Dj6ELZs=s48-c-k-c0x00ffffff-no-rj',
  },
];
